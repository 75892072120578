<template>
  <Modal open :size="ModalSize.MD">
    <template #header>
      <h2
        class="text-center text-xl text-on-surface pt-5 sm:pt-12 font-bold leading-none"
      >
        {{ $t('save-as-draft-modal.title') }}
      </h2>
    </template>
    <template #actions>
      <div
        class="flex flex-col md:flex-row justify-between md:justify-center py-10 px-5 self-center gap-5"
      >
        <Btn
          :size="BtnSize.LG"
          :variant="BtnVariant.FILLED"
          :color="BtnColor.PRIMARY"
          @click="emit('submit')"
        >
          {{ $t('common.save') }}
        </Btn>
        <Btn
          :size="BtnSize.LG"
          :variant="BtnVariant.OUTLINED"
          :color="BtnColor.PRIMARY"
          @click="emit('close')"
        >
          {{ $t('common.no-thanks') }}
        </Btn>
        <Btn :size="BtnSize.LG" @click="emit('cancel')">
          {{ $t('common.cancel') }}
        </Btn>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Btn, BtnColor, BtnSize, BtnVariant } from '~/components/btn';
import { Modal, ModalSize } from '~/components/modals/modal';

const emit = defineEmits<{
  close: [];
  cancel: [];
  submit: [];
}>();
</script>
